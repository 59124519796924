(function ($, site) {
  Drupal.behaviors.siteFooterCountryChooserV2 = {
    attach: function (context) {
      var $menu = $('.js-country-select__menu .menu--lvl-1', context);
      var $links = $('.menu__link', $menu);
      var $language = $('.js-language-select__wrapper', context);
      var $languageLinks = $('.language-select__link', context);
      var $select = $('select.country-select__selectbox', context);
      var $options = [];
      var defaultCountry =
        typeof site.defaultCountry !== 'undefined' ? site.defaultCountry.toLowerCase() : '';
      // Dynamically load flag image to country chooser from CMS.
      var flagUrl = $select.data('flag');
      var languageTimeout;

      if (this.attached) {
        return;
      }
      this.attached = true;

      site.util = site.util || {};
      site.util.refreshSelectBoxes = site.util.refreshSelectBoxes || function () {};

      $links.each(function () {
        var $me = $(this);
        var href = $me.attr('href');
        var text = $me.text();
        var attr = '';

        if (text.toLowerCase() === defaultCountry) {
          attr += ' selected="selected"';
        }

        $options.push('<option value="' + href + '"' + attr + '>' + text + '</option>');
      });
      $select.append($options);
      $('.js-country-select.selectBox').attr('aria-label', $select.attr('aria-label'));

      $select.next().attr({
        role: 'combobox',
        'aria-haspopup': 'true',
        'aria-expanded': 'false'
      });
      // Use wrapper from scripts.js instead of "$select.selectBox('refresh');" for touch devices.
      site.util.refreshSelectBoxes($select);
      if (typeof flagUrl !== 'undefined') {
        $('.country-select__selectbox', context)
          .css('background-image', 'url("' + flagUrl + '")')
          .addClass('selectboxflag');
      }

      $select.on('change', function () {
        var loc = $(this).val();

        if (loc) {
          window.location = loc;

          return true;
        }
      });

      $language.once().on('click', function () {
        var isExpanded = $language.attr('aria-expanded') !== 'false';
        $(this).toggleClass('active').attr('aria-expanded', !isExpanded);
      });
      $language.keypress(function (e) {
        var isExpanded = $language.attr('aria-expanded') !== 'false';
        if (e.which == 13) {
          $(this).toggleClass('active').attr('aria-expanded', !isExpanded);
        }
      });

      $languageLinks.focus(function () {
        if (languageTimeout) {
          clearTimeout(languageTimeout);
          languageTimeout = null;
        }
      });

      $languageLinks.blur(function () {
        languageTimeout = setTimeout(function () {
          $language.removeClass('active');
        }, 300);
      });

      $('body')
        .once()
        .on('click', function (e) {
          if (!$(e.target).is('[class*="language-select"]')) {
            $language.removeClass('active');
          }
        });
    },
    attached: false
  };
})(jQuery, window.site || {});
